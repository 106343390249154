import React from 'react';
import FixedImage from '../../components/fixed-image';
import FixedImageMapPin from '../../components/fixed-image-mappin';
import FluidImage from '../../components/fluid-image';
import { Container, Jumbotron, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import ModalForm from "../../components/layout/form/modalForm";
import { navigate } from 'gatsby';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css"
import Tabs from "../../components/layout/tab/tabs.js";
import "../../components/layout/tab/tab.scss";

import './lp-downtown.scss';
import Footer from "../../components/layout/footer/footer";

const Podio = require('podio-js').api;

// get the API id/secret
const clientId = 'local-leasing-4h21wh';
const clientSecret = process.env.PODIO_CLIENT_SECRET;

// get the app ID and Token for appAuthentication
const appId = process.env.PODIO_APP_ID;
const appToken = process.env.PODIO_APP_TOKEN;

// get the campaign ID
const campaignID = parseInt(process.env.LL_CAMPAIGN_ID);

class LPM extends React.Component {
    constructor(props){
        super(props);
        this.state = {open: false};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        // extract form data
        const formData = new FormData(event.target)

        formData.append('first-name', document.getElementById('first-name').value)
        formData.append('last-name', document.getElementById('last-name').value)
        
        // convert FormData to json object
        const jsonString = JSON.stringify(Object.fromEntries(formData));
        const json = JSON.parse(jsonString)

        //Add email, phone, campaign ID, tenant status to json object
        json.email = {
            'type': 'other',
            'value': document.getElementById('email').value,
        }

        json.phone = {
            'type': 'other',
            'value': document.getElementById('phone').value,
        }

        json.relationship = campaignID

        json['tenant-approval-status'] = 2

        // instantiate the SDK
        const podio = await new Podio({
            authType: 'app',
            clientId: clientId,
            clientSecret: clientSecret,
        });

        podio.authenticateWithApp(appId, appToken, (err) => {

          if (err) throw new Error(err);

          podio.isAuthenticated().then(() => {
              // Ready to make API calls in here...
              var requestData = {fields: json};
              
              podio.request('POST', `/item/app/${appId}/`, requestData).then((responseData) => {
                // response, if available
                  if(responseData.item_id !== null){
                    localStorage.setItem('item-Id', responseData.item_id)
                    navigate('/fb-thank-you');
                  }
                });         
          }).catch(err => console.log(err));
        })
    }
    
    onOpenModal = () => {
      this.setState({ 
        open: true
      });
    }
  
    onCloseModal = () => {
      this.setState({
        open: false
      });
    }

    render() {
        const { open } = this.state;
        const styles = {
            overlay: {
                'zIndex': '9999999',
            },
            modal: {
                'borderRadius': '5px',
                'background-color': 'rgba(255,255,255, .3)',
                //'font-size': '1rem',
                'padding': '0px',
            },
            closeButton: {
                'cursor': 'pointer',
            }
        }

        return (
            <div>
                <Modal open={ open } onClose={this.onCloseModal} styles={styles} center>
                    <ModalForm/>
                </Modal>
                <Jumbotron className="jumbo">
                    <Container className="hAuto">
                        <Row className="hAuto justify-content-center">
                            <Col className="align-self-center flexContainer" xs="12" md="5" lg="5">
                                <div className="llLogo mCenter">
                                    <FluidImage filename="LLLStackedHighRes-EditedGreen.png" alt="local leasing" />
                                </div>                                
                                <h2 className="jumboText mt-5 mb-5 mCenter">Modern Living In The Heart Of The City</h2>
                                <h4 className="textWhite displayNone">Immediate Availability</h4>
                                <h4 className="textBlue displayNone">Downtown & Surrounding Areas</h4>
                            </Col>
                            <Col className="jForm align-self-center" xs="10" md="7" lg="5">
                                <h2 className="text-center textBlack formText">Book A Showing Today</h2>
                                <p className="text-center textBlack">Come find your new home today!</p>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormGroup className="mt-2 mb-2">
                                        <Label for="first-name">First Name *</Label>
                                        <Input type="text" id="first-name" name="first-name" required/>
                                    </FormGroup>
                                    <FormGroup className="mt-2 mb-2">
                                        <Label for="last-name">Last Name *</Label>
                                        <Input type="text" id="last-name" name="last-name" required/>
                                    </FormGroup>
                                    <FormGroup className="mt-2 mb-2">
                                        <Label for="email">Email *</Label>
                                        <Input type="email" id="email" name="email" required/>
                                    </FormGroup>
                                    <FormGroup className="mt-2 mb-2">
                                        <Label for="phone">Phone *</Label>
                                        <Input type="tel" id="phone" name="phone" required/>
                                    </FormGroup>
                                    <FormGroup className="mt-2 mb-2">
                                        <Label for="property-of-interest-2">Property Of Interest</Label>
                                        <Input type="select" id="property" name="property-of-interest-2" className="propOfInt" required>
                                            <option></option>
                                            <option>900 NW 13th St</option>
                                            <option>901 NW 13th St</option>
                                            <option>910 NW 13th St</option>
                                            <option>1700 N Robinson Ave</option>
                                            <option>2400 NW 20th St</option>
                                            <option>2426 NW 20th St</option>
                                            <option>437 NW 26th St</option>
                                            <option>1100 NW 27th St</option>
                                            <option>3244 NW 31st St</option>
                                        </Input>
                                    </FormGroup>
                                    <Row className="justify-content-center">
                                        <Button className="subButton mt-3" size="lg" color="primary">Request A Showing</Button>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                <Container className="benContainer" fluid={true}>
                    <Row className="benHeader justify-content-center">
                        <h4 className="textBlack">Bright Life. Big City. Rent Downtown.</h4>
                    </Row>
                </Container>
                <Container className="benefits mt-5 mb-5">
                    <Row className="justify-content-around">
                        <Col className="col-3" xs="8" md="3">
                            <Row className="justify-content-center mb-3">
                                <FixedImage filename="allh1.jpg" alt="apt-left"/>
                            </Row>
                            <Row className="justify-content-center text-center">
                                <p>
                                    Setting a vivid new standard of 
                                    affordable rentals and luxury living in Downtown & 
                                    surrounding areas.
                                </p>
                            </Row>
                        </Col>
                        <Col  className="col-3" xs="8" md="3">
                            <Row className="justify-content-center mb-3">
                                <FixedImage filename="allh2.jpg" alt="apt-middle"/>
                            </Row>
                            <Row className="justify-content-center text-center">
                                <p>
                                    A central location situated close to 
                                    numerous restaurants, cafés, retail shops,
                                    city parks, nightlife, & downtown OKC.
                                </p>
                            </Row>
                        </Col>
                        <Col  className="col-3" xs="8" md="3">
                            <Row className="justify-content-center mb-3">
                                <FixedImage filename="allh3.jpg" alt="apt-right"/>
                            </Row>
                            <Row className="justify-content-center text-center">
                                <p>
                                    Near downtown allowing you
                                    to live in the limelight and say goodbye to
                                    your commute to work.
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                
                <Container className="midtownMap" fluid={true}>
                    <Row className="hAuto justify-content-center align-items-center">
                        <Col>
                            <Row className="justify-content-center align-items-center">
                                <Col className="col-2" xs="4" md="2">
                                    <FixedImageMapPin filename="mappin.png" alt="map pin"/>
                                </Col>
                                <Col className="col-4" xs="8" md="4">
                                <h4 className="textWhite text-center">
                                    Multiple Locations 
                                    In The Hottest Areas
                                    In OKC
                                </h4>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="" xs="12" md="6">
                            <Row className="container-fluid justify-content-center">
                                <Row>
                                    <h1 className="textBlue mb-4 mText">BEAUTIFUL LOCATION</h1>
                                </Row>
                                <Row>
                                    <h5 className="textWhite mPText">
                                        Oklahoma City offers a thriving nightlife scene with 
                                        concentrations of popular spots for music, dancing and 
                                        drinks in the Bricktown Entertainment District, the 
                                        Paseo Arts District, Midtown area, Plaza District, and 
                                        Western Avenue area. The arts abound, and culture 
                                        flourishes with options such as the Oklahoma City Philharmonic 
                                        orchestra, the Oklahoma City Ballet, vibrant art 
                                        districts, a diverse music scene, bountiful drama productions 
                                        and an acclaimed arts festival that draws nearly 1 
                                        million visitors each year.
                                    </h5>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4 mb-4">
                    <Row className="justify-content-center">
                        <h2>Apartments</h2>
                    </Row>
                    <Row className="justify-content-center">
                        <p>Locations & Details</p>
                    </Row>
                    <Row className="container-fluid row justify-content-center mt-2">
                        <Tabs>                          
                            <Row label="900 NW 13th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            BRAND NEW Historically renovated Iroquis Building now 
                                            pre-leasing with expected move in date of 5/1/20. These 
                                            units have an on-site washer and dryer. Fully loaded with stainless steel appliances 
                                            including dishwasher, stove vent hood, refrigerator, 3cm 
                                            quartz counter tops with new marble back splash and 
                                            bathroom flooring, custom cabinets,refinished hardwood 
                                            floors, modern recessed LED light fixtures in both bedrooms 
                                            and living room, 2 inch faux blinds, kolbe windows, central 
                                            HVAC and vaulted ceilings. Each unit is all electrical, 
                                            paired with a high R value will keep the bills to a minimum. 
                                            The property has security cameras and secure gated parking.
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1-2
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1-2
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                450-800
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Gated
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $695-1265/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel" swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename="900-exterior.jpg" alt="Exterior 900" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-LR.jpg" alt="900 Living Room" />                                                
                                            </div>
                                            <div>
                                                <FluidImage filename="900-kitchen.jpg" alt="900 Kitchen" />
                                            </div>
                                            <div>
                                                <FluidImage filename="Apt-100.jpg" alt="900 apt 101" />
                                            </div>
                                            <div>
                                                <FluidImage filename="Apt-103.jpg" alt="900 apt103" />
                                            </div>
                                            <div>
                                                <FluidImage filename="Apt-106.jpg" alt="900 apt106" />
                                            </div>
                                            <div>
                                                <FluidImage filename="Apt-FP-2bed.jpg" alt="900 2 bed apt" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-front.jpg" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-206-01.jpg" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-206-04.jpg" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-206-05.jpg" />
                                            </div>
                                            <div>
                                                <FluidImage filename="900-206-07.jpg" />
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="901 NW 13th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            This Historical Beasley Building located in Midtown is 
                                            uniquely designed and filled with character. Each unit includes: 
                                            hard wood flooring, ceramic tile, stainless steel appliances, crown 
                                            molding, high ceilings, canned lighting, over sized windows 
                                            privet gates with excellent parking, laundry facility on premises. 
                                            In addition we have FREE Commercial WI-FI that has fast speeds 
                                            for all your streaming needs. Great location, in between The Plaza 
                                            District and the heart of Midtown.
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                700-850
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Gated
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Hook Ups/On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $695-820/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='901-exterior.jpg' alt="901 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='901-3D.jpg' alt="901 floor plan"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='901-kitchen.jpg' alt="901 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='901-kitchen1.jpg' alt="901 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='901-bedroom3.jpg' alt="901 bed" />
                                            </div>
                                            <div>
                                                <FluidImage filename='901-bedroom1.jpg' alt="901 bed1"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="910 NW 13th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            Cute Historical Midtown Building with a Mid-century 
                                            Paseo feel is available, each with its own uniquely 
                                            designed character Units include: hard wood flooring, 
                                            ceramic tile, stainless steel appliances, crown molding, 
                                            high ceilings, canned lighting, over sized windows privet 
                                            gates with excellent parking, laundry facility on premises. 
                                            In addition we have FREE Commercial WI-FI that has fast 
                                            speeds for all your streaming needs. Great location, in 
                                            between The Plaza District and the heart of Midtown.
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                700-850
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Gated
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $695-820/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='910-exterior1.png' alt="910 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='910-lr.jpg' alt="910 living room"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='910-kitchen1.jpg' alt="910 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='910-kitchen.jpg' alt="kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='910-bed.jpg' alt="910 bed"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="1700 N Robinson Ave">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            Historical 1 bedroom apartments with Security Code 
                                            Entrances. Central Heat & Air Conditioning located 
                                            in Heritage Hills neighborhood. Only minutes from 
                                            Midtown and Downtown Oklahoma City, walking distance 
                                            to NW 23rd & Walker Entertainment District with great 
                                            restaurants, bars, coffee shops and retail. Close 
                                            proximity to major employers such as Oklahoma State 
                                            Capitol Complex, OU Health Sciences Center, St. Anthony 
                                            Hospital, Oklahoma City University and Downtown Oklahoma 
                                            City. Great location for under market rent!
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                850
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Private/Street
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $695-795/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='1700-exterior.jpg' alt="1700 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-lr.jpg' alt="1700 living room"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-kitchen.jpg' alt="1700 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-bed.jpg' alt="1700 bed"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-bath.jpg' alt="1700 bath"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-kit-lr.jpg' alt="1700 kitchen/LR"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1700-lr-kit.jpg' alt="1700 LR"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="2400 NW 20th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                        Colonial Revival duplex home in the Crestwood district. 
                                        This house is fully loaded with stainless steel appliances 
                                        including dishwasher, stove vent hood, refrigerator, glass 
                                        top stove and 2 inch Granite countertops with new backsplash. 
                                        Subway tile backsplash and lots of built in cabinet space. 
                                        Newly refinished hardwood floors throughout. Modern light 
                                        fixtures in both bedrooms and living room. This historical 
                                        home has been brought back to its original beauty with modern 
                                        amenities. Large covered front porch. Faux fireplace. Blinds 
                                        all throughout. BRAND NEW WINDOWS AND CENTRAL HEAT AND AIR
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                2
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1150
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Covered/Gated/Street
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Hook Ups
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $895-975/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='2400-exterior.jpg' alt="2400 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2400-fp.jpg' alt="2400 floor plan"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2400-lr.jpg' alt="2400 living room"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2400-kit.jpg' alt="2400 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2400-bed.jpg' alt="2400 bed"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2400-bed1.jpg' alt="2400 bed1"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="2426 NW 20th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                        Beautiful Bungalow unit in the heart of the 
                                        historical Crestwood. The unit includes original 
                                        refinished hard wood floors throughout, new 
                                        bathroom fixtures and tile, and central heat / air. 
                                        Lawn care yard maintenance and pest control included.
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                800
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Hook Ups
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Private/Street
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $595-750/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='2426-exterior.jpg' alt="2426 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-fp.jpg' alt="2426 floor plan"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-lr.jpg' alt="2426 living room"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-lr2.jpg' alt="2426 lr2"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-kit.jpg' alt="2426 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-dining.jpg' alt="2426 dining"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-bed.jpg' alt="2426 bed"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='2426-bath.jpg' alt="2426 bath"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="437 NW 26th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                        Beautiful Colonial Revival unit in the 
                                        heart of the historical Paseo District. Shops and 
                                        restaurants are 2 blocks away from both Paseo Drive 
                                        and the new fashionable Uptown District. The unit includes 
                                        original refinished hard wood floors throughout, new 
                                        bathroom fixtures and tile, and central heat / air. 
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Studio & 1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                550-650
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Private/Covered/Street
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $595-695/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='437-exterior.jpg' alt="437 exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='437-lr.jpg' alt="437 lr"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='437-lr1.jpg' alt="437 lr1"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='437-lr2.jpg' alt="437 lr2"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='437-lr3.jpg' alt="437 lr3"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="1100 NW 27th St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            Beautiful Colonial Revival unit garage apartment 
                                            in the heart of the historical Paseo District and Asian 
                                            District. Shops and restaurants are just blocks away from 
                                            both Paseo Drive and Western / Classen and the new fashionable 
                                            Uptown District. The unit includes a beautiful furnished place 
                                            throughout, new bathroom fixtures and tile. Gorgeous 3cm quartz 
                                            with a bar. Lawn care yard maintenance and pest control included.
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Studio to 4
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1-2
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                500-1400
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                On Site
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Private/Covered
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $750-995/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='1100-ext-front.jpg' alt="1100 Exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-extback.jpg' alt="1100 Exterior"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-lrkit.jpg' alt="1100 lr kit"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-lr.jpg' alt="1100 lr"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-studio2.jpg' alt="1100 lr2"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-studio.jpg' alt="1100 lr3"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-kit.jpg' alt="1100 kitchen"/>
                                            </div>
                                            <div>
                                                <FluidImage filename='1100-bed2.jpg' alt="1100 bed"/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                            <Row label="3244 NW 31st St">
                                <Row className="row container">
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Row>
                                            COMING SOON! MAY 1st - Beautiful ranch style duplex home west 
                                            of I-44 by Will Rogers Park and Cleveland Elementry and Kaiser. 
                                            This house is fully loaded with stainless steel appliances 
                                            including dishwasher, stove vent hood, refrigerator, glass top 
                                            stove and 3cm quartz countertops with new backsplash. Great 
                                            storage space. Newly refinished hardwood floors throughout and 
                                            marble floors. Modern light fixtures in both bedrooms and living 
                                            room. This historical home has been brought back to its original 
                                            beauty with modern amenities. BRAND NEW WINDOWS AND CENTRAL HEAT 
                                            AND AIR!
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bedrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                2
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Bathrooms
                                            </Col>
                                            <Col className='text-right col-6'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Total Area, SQ FT
                                            </Col>
                                            <Col className='text-right col-6'>
                                                850
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Washer/Dryer
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Hook Ups
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Parking
                                            </Col>
                                            <Col className='text-right col-6'>
                                                Driveway/Street
                                            </Col>
                                        </Row>
                                        <Row className="container row mt-3 spec-underline">
                                            <Col className='text-left col-6'>
                                                Price
                                            </Col>
                                            <Col className='text-right col-6'>
                                                $795/month
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-6" xs="12" md="6">
                                        <Carousel className="mCarousel"  swipeable={true} showThumbs={false} showArrows={true}>
                                            <div>
                                                <FluidImage filename='3244-NW-31-1.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-2.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-3.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-4.jpg'/>
                                            </div>
                                            <div>
                                            <FluidImage filename='3244-NW-31-5.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-6.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-7.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-8.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-9.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-10.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-11.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-12.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-13.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-14.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-15.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-16.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-17.jpg'/>
                                            </div>
                                            <div>
                                                <FluidImage filename='3244-NW-31-18.jpg'/>
                                            </div>
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Row>
                        </Tabs>
                    </Row>
                </Container>
                <Container className="cityScape mt-5 mb-5" fluid={true}>
                    <Row className="hAuto align-items-center">
                        <Col>
                            <Row className="justify-content-center mb-3">
                                <h3 className="textWhite qText">What Are You Waiting For?</h3> 
                            </Row>
                            <Row className="justify-content-center">
                                <Button className="subButton2" color="primary" size="lg" onClick={this.onOpenModal}>Request A Showing</Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container className="mb-3">
                    <Row className="justify-content-center text-center">
                        <Footer/>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default LPM;


