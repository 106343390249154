import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { navigate } from 'gatsby';

const Podio = require('podio-js').api;

// get the API id/secret
const clientId = 'local-leasing';
const clientSecret = process.env.PODIO_CLIENT_SECRET;

// get the app ID and Token for appAuthentication
const appId = process.env.PODIO_APP_ID;
const appToken = process.env.PODIO_APP_TOKEN;

// get the campaign ID
const campaignID = parseInt(process.env.LL_CAMPAIGN_ID);

class ModalForm extends React.Component {
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        // extract form data
        const formData = new FormData(event.target)

        formData.append('first-name', document.getElementById('first-name-modal').value)
        formData.append('last-name', document.getElementById('last-name-modal').value)
    
        // convert FormData to json object
        const jsonString = JSON.stringify(Object.fromEntries(formData));
        const json = JSON.parse(jsonString)

        //Add email, phone, campaign ID, tenant status to json object
        json.email = {
            'type': 'other',
            'value': document.getElementById('email-modal').value,
        }

        json.phone = {
            'type': 'other',
            'value': document.getElementById('phone-modal').value,
        }
        
        json.relationship = campaignID

        json['tenant-approval-status'] = 2

        // instantiate the SDK
        const podio = await new Podio({
            authType: 'app',
            clientId: clientId,
            clientSecret: clientSecret
        });

        podio.authenticateWithApp(appId, appToken, (err) => {

          if (err) throw new Error(err);

          podio.isAuthenticated().then(() => {
              // Ready to make API calls in here...
              var requestData = {fields: json};
              
              podio.request('POST', `/item/app/${appId}/`, requestData).then((responseData) => {
                // response, if available
                  if(responseData.item_id !== null){
                    localStorage.setItem('item-Id', responseData.item_id)
                    navigate('/fb-thank-you');
                  }
                });         
          }).catch(err => console.log(err));
        })
    }

    render() {
        return (
            <Container className="hAuto mForm">
                <Row className="hAuto justify-content-center">
                    <Col className="jForm align-self-center">
                        <h2 className="text-center textBlack">Book A Showing Today</h2>
                        <p className="text-center textBlack">Come find your new home today!</p>
                            <Form onSubmit={this.handleSubmit}>
                                <FormGroup className="mt-3 mb-3">
                                    <Label for="first-name">First Name *</Label>
                                    <Input type="text" id="first-name-modal" name="first-name" required/>
                                </FormGroup>
                                <FormGroup className="mt-3 mb-3">
                                    <Label for="last-name">Last Name *</Label>
                                    <Input type="text" id="last-name-modal" name="last-name" required/>
                                </FormGroup>
                                <FormGroup className="mt-3 mb-3">
                                    <Label for="email">Email *</Label>
                                    <Input type="email" id="email-modal" name="email" required/>
                                </FormGroup>
                                <FormGroup className="mt-3 mb-3">
                                    <Label for="phone">Phone *</Label>
                                    <Input type="tel" id="phone-modal" name="phone" required/>
                                </FormGroup>
                                <FormGroup className="mt-3 mb-3">
                                    <Label for="property-of-interest-2">Property Of Interest</Label>
                                    <Input type="select" id="property-modal" name="property-of-interest-2" className="propOfInt" required>
                                        <option></option>
                                        <option>900 NW 13th St</option>
                                        <option>901 NW 13th St</option>
                                        <option>910 NW 13th St</option>
                                        <option>1700 N Robinson Ave</option>
                                        <option>2400 NW 20th St</option>
                                        <option>2426 NW 20th St</option>
                                        <option>437 NW 26th St</option>
                                        <option>1100 NW 27th St</option>
                                        <option>3244 NW 31st St</option>
                                    </Input>
                                </FormGroup>
                                <Row className="justify-content-center">
                                    <Button className="subButton mt-3" size="lg" color="primary">Request A Showing</Button>
                                </Row>
                            </Form>
                    </Col>
                </Row>
            </Container>
        )
    };
}

export default ModalForm;