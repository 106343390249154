import React from 'react';

import LPM from './landingPage/lp-downtown'
import SEO from '../components/seo';

class RentDowntown extends React.Component {
    render() {
        return (
            <div>
                <SEO title='Local Leasing' description="Local Leasing | Modern Living In The Heart Of The City"/>
                <LPM />
            </div>
        )
    };
}

export default RentDowntown;