import React from 'react'
import { StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const FixedImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.fluid;
      return (
        <Img
          alt={props.alt}
          fluid={imageSizes}
        />
      );
    }}
  />
)

export default FixedImage