import React from "react";

import "./footer.scss";

class Footer extends React.Component {
    render () {
        return (
            <div className="footer">
                <span>Local Leasing</span>
                <br/>
                <br/>
                <span>1111 N Lee Ave, Suite 218</span>
                <br/>
                <span>Oklahoma City, OK 73103</span>
                <br/>
                <br/>
                <span>Phone: <a href="tel:+14054009959">(405)400-9959</a> | Email: <a href="mailto:info@localleasing.com">Info@localleasing.com</a></span>
                <br/>
                <span><a href="./privacy-policy" target="_blank">Privacy Policy</a> | <a href="./terms-of-service" target="_blank">Terms Of Service</a></span>
            </div>
        )
    }
}

export default Footer;